/* * {
  margin: 0;
  padding: 0;
  overflow: visible;
  font-family: "Poppins", sans-serif;
}
html,
body {
  margin: 0;
  height: 100%;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  overflow: scroll;
}
::-webkit-scrollbar {
  width: 0;
} */

.react-datepicker-wrapper {
  width: 100%;
}

.custome-height__profile {
  height: calc(100vh - 76px);
}

.min-custome-height__profile {
  min-height: calc(100vh - 76px);
}
.popup-menu {
  position: fixed;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  width: 150px;
}

.popup-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.popup-menu li {
  padding: 8px;
  cursor: pointer;
}

.popup-menu li:hover {
  background-color: #f0f0f0;
}
